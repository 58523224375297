.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 0.7rem;
    border-top: 0.2rem #000 solid;
    max-height: 12vh;

    .contact-email {
        font-size: 0.8rem;
        padding-left: 2rem;
    }

    .fa {
        font-size: 2rem;
        width: 3rem;
        text-align: center;
        text-decoration: none;
    }

    .fa:hover {
        opacity: 0.7;
    }

    .logos {
        padding: 2rem;
    }

    /* Linkedin */
    .fa-linkedin {
        /* background: #a23b3b; */
        color: rgb(0, 0, 0);
    }

    .fa-github {
        /* background: #a23b3b; */
        color: rgb(0, 0, 0);
    }

    .copyright {
        font-size: 0.8rem;
    }

    .footer-flag {
        max-height: 4vh;
        padding-left: 4rem;
    }

    .languages {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        max-height: 35vh;

        .contact-email {
            font-size: 1rem;
            padding-bottom: 1rem;
        }

        .fa {
            font-size: 3rem;
            width: 4rem;
            text-align: center;
            text-decoration: none;
        }

        .logos {
            padding: 0.5rem;
        }

        .copyright {
            font-size: 1rem;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }

        .footer-flag {
            max-height: 4vh;
            padding: 1rem;
            padding-bottom: 2rem;
        }

        .languages {
            color: #000000;
            text-decoration: none;
            font-size: 1.5rem;
            display: flex !important;
            flex-direction: row;
            margin: 2px;
            padding-top: 1rem;
            padding-bottom: 1rem;

            p {
                margin: 2px;
            }

            .lang {
                padding: 0.1rem;
                margin: 0.1rem;
                min-width: 2rem;
                text-align: center;
            }

            .currentLang {
                font-weight: bold;
            }

            div:hover:not(.currentLang) {
                /* -webkit-text-stroke-width: 1px; */
                /* font-weight: bold; */
                color: #B65832;
            }
        }
    }
}