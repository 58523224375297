.navbar {
    min-height: 6vh;
    max-height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    width: 100vw;
    border-bottom: 0.2rem #000 solid;
}

.navbar-logo {
    max-height: 3rem;
    padding: 1.4rem 0rem 1rem 1rem;
}

.navbar {
    .nav-name {
        display: flex;
        flex-direction: column;
        color: #000;
        padding: 1rem 1rem 1rem 0;

        .title {
            font-weight: bold;
            display: block;
            text-align: left;
            font-size: 1.5rem;
            justify-content: start;
        }

        .name {
            font-size: 1.1rem;
            display: block;
            text-align: left;
            font-style: italic;
        }

    }

    .active {
        color: #B65832;
        /* background-color: #B65832; */
    }

    .languages {
        color: #000000;
        text-decoration: none;
        font-size: 1rem;
        display: flex;
        flex-direction: row;
        margin: 2px;
        position: absolute;
        right: 0;
        text-align: center;

        p {
            margin: 2px;
        }

        .lang {
            padding: 0.1rem;
            margin: 0.1rem;
            min-width: 1.3rem;
        }

        .currentLang {
            font-weight: bold;
        }

        div:hover:not(.currentLang) {
            /* -webkit-text-stroke-width: 1px; */
            /* font-weight: bold; */
            color: #B65832;
        }
    }
}

.navbar-links {
    list-style: none;
    display: flex;
}

.navbar-links li {
    margin: 0 1rem;
}

.navbar-links a {
    color: #000000;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: bold;
}

.navbar-links a:hover:not(.active) {
    /* -webkit-text-stroke-width: 1px; */
    /* font-weight: bold; */
    color: #B65832;
}

/* MOBILE */
@media screen and (max-width: 768px) {

    .nav {
        /* height: calc(var(--header-height) + 1rem); */
    }

    .navbar {
        max-height: 15vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        width: 100vw;
        border-bottom: 0.2rem #000 solid;
        padding: 0;
        padding-bottom: 0.7rem;

        .nav-name {
            display: flex;
            flex-direction: column;
            color: #000;
            padding: 0;

            .title {
                font-weight: bold;
                display: block;
                text-align: left;
                font-size: 2rem;
                justify-content: start;
            }

            .name {
                font-size: 2rem;
                display: block;
                text-align: left;
                font-style: italic;
            }
        }

        .navbar-logo {
            max-height: 4.5rem;
            padding: 0;
            /* padding: 1.4rem 0rem 1rem 1rem; */
        }

        .nav-toggle {
            font-size: 4rem;
            position: absolute;
            right: 0;
            top: 0;
        }

        .nav-toggle.active {
            display: none;
        }

        .nav-close {
            z-index: 6;
            font-size: 4rem;
            position: absolute;
            right: 0;
            top: 0;
        }

        .nav-close:not(.active) {
            display: none;
        }
    }

    .navbar-links:not(.active) {
        display: none;
    }

    .navbar-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: 0vh;
        z-index: 5;
        background-color: #ffffff;
        border-left: 2px black solid;
        border-bottom: 2px black solid;
        padding: 0;
        margin: 0;
        padding-right: 2.5rem;
        padding-left: 0;
        padding-top: 0.4rem;

        li {
            padding: 0.5rem 0;
        }

        a {
            color: #000000;
            text-decoration: none;
            font-size: 2rem;
            font-weight: bold;
        }
    }

    .languages {
        display: none !important;
    }

    /* .nav-toggle {
        display: flex;
        align-items: end;
    }

    .nav-toggle div:hover {
        background-color: #B65832;
    }

    .nav-close {
        display: none;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 2.5rem;
    } */
}

/* DESKTOP */
@media screen and (min-width: 768px) {
    /* .nav {
        height: calc(var(--header-height) + 1rem);
    } */

    .nav-toggle,
    .nav-close {
        display: none;
    }

    /* .nav__list {
        flex-direction: row;
        column-gap: 1.5rem;
    } */
}