.home,
.about,
.services,
.contact {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  overflow-y: scroll;
  max-height: 78vh;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.home {
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  color: #000;
  padding-left: 10vh;
  padding-top: 5vh;
  font-size: 1.1rem;

  .text {
    font-size: 1.2rem;
    display: block;
    text-align: left;
    padding-bottom: 1rem;
  }

  .title {
    display: block;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
  }

  .name {
    font-size: 1.5rem;
    display: block;
    text-align: left;
    font-style: italic;
  }

  .slogan {
    font-size: 1.5rem;
  }
}

.about {
  display: flex;
  flex-direction: column;
  padding-left: 10vh;
  font-size: 1.1rem;

  p,
  li {
    width: 33vw;
    text-align: justify;
  }
}

.services {
  display: flex;
  flex-direction: column;
  padding-left: 10vh;
  font-size: 1.1rem;

  p,
  li {
    width: 33vw;
    text-align: justify;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  padding-left: 10vh;
  font-size: 1.1rem;

  p,
  li {
    width: 33vw;
    text-align: justify;
  }
}

@media screen and (max-width: 768px) {

  .home,
  .about,
  .services,
  .contact {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    overflow-y: scroll;
    max-height: 60vh;

    .slogan {
      text-align: left;
    }

    h1,
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }

    p,
    li {
      width: 80vw;
      text-align: justify;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}